import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
} from "reactstrap";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar light expand="md" className="thenav">
        <NavbarBrand href="/">
          <div className="logoContainer">
            <img
              className="headerlogo"
              src={require("./../assets/images/blueguy.png")}
              alt="one"
            />
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavLink tag={RRNavLink} to="/" activeclassname="is-active">
              Home
            </NavLink>
            <NavLink tag={RRNavLink} to="/about" activeclassname="is-active">
              About
            </NavLink>
            <NavLink
              tag={RRNavLink}
              to="/equipment"
              activeclassname="is-active"
            >
              Equipment
            </NavLink>
            <NavLink tag={RRNavLink} to="/services" activeclassname="is-active">
              Services
            </NavLink>
            <NavLink tag={RRNavLink} to="/contact" activeclassname="is-active">
              Contact
            </NavLink>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
