import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import About from "../views/About";
import Terms from "../views/Terms";
import Services from "../views/Services";
import Contact from "../views/Contact";
import Equipment from "../views/Equipment";
import Notfound from "../views/NotFound";

const RoutesWrapper = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/equipment" element={<Equipment />} />
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
};

export default RoutesWrapper;
