import React from 'react'
import Layout from './hoc/Layout';
import RoutesWrapper from './routes/RoutesWrapper';
import './App.css';


const App = () => {
  return (
    <>
      <Layout>
        <RoutesWrapper />
      </Layout>
    </>
  );
}

export default App;
