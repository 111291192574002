import React from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";

const Footer = () => {
  const { Paragraph } = Typography;
  return (
    <div>
      <div className="footerWrap">
        <div>
          <Paragraph className="paragraph">
            <span className="Strtitle">Ocean-Ease LTD</span>
            <br />
            63, OGUNLANA DRIVE SURULERE, LAGOS.
            <br />
            info@oceanease.com.ng <br /> +2348038592166
          </Paragraph>
        </div>

        <div className="footerIcon">
          <FacebookOutlined />
          <TwitterOutlined />
          <InstagramOutlined />
        </div>

        <div className="footerMenu">
          <p>
            <NavLink className="Link" to="/about">
              About
            </NavLink>
          </p>
          <p>
            <NavLink className="Link" to="/contact">
              Contact
            </NavLink>
          </p>
          <p>
            <NavLink className="Link" to="/terms">
              Terms/Conditions
            </NavLink>
          </p>
          <p className="right">© Copyright 2019 - All Rights Reserved</p>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
