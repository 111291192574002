import React from "react";
import { NavLink } from "react-router-dom";
import { ArrowRightOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Typography, Collapse } from "antd";
import QueueAnim from "rc-queue-anim";

const Equipment = () => {
  const { Title } = Typography;
  const Panel = Collapse.Panel;

  return (
    <div>
      <div className="breadLink">
        <NavLink to="/" exact={true}>
          Home
        </NavLink>{" "}
        <div style={{ padding: "0 20px" }}>
          <ArrowRightOutlined />
        </div>{" "}
        <div>Equipment</div>
      </div>

      <div className="contentWrapEquip">
        <div className="ROV">
          <img src={require("./../assets/images/equip.png")} alt="one" />
        </div>

        <div className="contentEquip">
          <QueueAnim
            key="1"
            delay={400}
            ease={["easeOutQuart", "easeInOutQuart"]}
          >
            <Title key="0">Equipment</Title>
            <p key="1">
              Our value driven, high quality service offering is achieved by
              knowledgeable and experienced personnel utilising high quality ROV
              system and tooling. Ocean-Ease ltd has an impressive team of
              highly skilled, dedicated support team to ensure that all of our
              equipment is serviced and managed effectively and efficiently
              throughout the project cycle as well as equipment and accessories.
              We are certain that we are the subsea ROV Inspection Company
              suitable for your needs. With our unique Inspection ROV system and
              experienced ROV personnel, we deliver high quality, reliable and
              cost effective Subsea Inspection service.
            </p>
            <Title level={3} key="3">
              Our equipments include:
            </Title>
            <Collapse
              key="2"
              bordered={false}
              // className='accord'
              defaultActiveKey={["1"]}
              // onChange={callback}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              accordion
            >
              <Panel header="Seaeye Tiger Rov" key="1">
                <p>
                  The Tiger is a compact and versatile ROV that has a forward
                  speed of 3 knots and can handle a variety of subsea sensors
                  and tooling. The Tiger is suited for most subsea inspection
                  tasks and light manipulation tasks, with a payload capacity of
                  32 Kilograms. The Tiger comes with a color and black/white
                  camera on a tilt platform, a depth sensor, compass and
                  obstacle avoidance SONAR. Optionally a multi beam sonar can be
                  mounted along with other survey equipment.
                </p>
                <p>Examples of subsea tasks that can be performed are:</p>
                <ul>
                  <li>Wall thickness measurements</li>
                  <li>Pipeline inspection</li>
                  <li>Dive support</li>
                  <li>Flooded Member Detection</li>
                  <li>Cathodic Protection measurements</li>
                  <li>Cleaning tasks</li>
                  <li>
                    Small manipulation tasks as cutting ropes or picking up
                    rigging
                  </li>
                </ul>
              </Panel>

              <Panel header="Hull Crawler" key="2">
                <p>
                  The Magnetic Hull Crawler is a three wheeled crawler, with
                  magnets inside of the wheel hubs and a silicon and rubber
                  cover for traction and to avoid damaging the surface it is
                  driving on.
                </p>
                <p>
                  The magnets allow the vehicle to "stick" to a steel or ferrous
                  surface, driving vertically or even upside down on the steel.
                  The back pivot wheel is able to turn 360 degrees, allowing the
                  vehicle to turn in one position with zero turning radius,
                  making it extremely maneueverable.
                </p>
                <p>
                  It can drive through marine growth and around smooth bends and
                  surfaces, making it ideal for larger diameter pipes and ship
                  hulls.
                </p>
              </Panel>

              <Panel header="Mini Rov" key="3">
                <p>
                  The ROV is powered by two Lithium Ion battery tubes on the
                  bottom of the ROV. These batteries last for up to 8 hours and
                  only take 3 hours to recharge. Included in the package is a
                  spare set of batteries, these battery tubes or skis can be
                  interchanged very quickly to keep operations up and running.
                  At the front of the skis are additional LED floodlights for
                  even better illumination for video.
                </p>
                <p>
                  The ROV is controlled by a unique BRIDGE software, which
                  enables the operator see and record data from many different
                  sensors all from a handheld controller. When you add
                  positional sensors such as the USBL, you can see your position
                  on screen via a Google map and record your position as it
                  changes over time along with the video and your other sensor
                  data. Imaging sonars are also integrated directly on the
                  handheld, allowing you to see and record sonar data side by
                  side with your position and video footage.{" "}
                </p>
              </Panel>

              <Panel header="Workclass Rov" key="4">
                <p>
                  Workclass Rov is a heavy work class vehicle used for oil field
                  maintenance and construction, including in ultradeep waters,
                  and for monitoring the seabed and gathering data to design of
                  oil fields or subsea pipelines.{" "}
                </p>
              </Panel>
            </Collapse>{" "}
          </QueueAnim>
        </div>
      </div>
    </div>
  );
};
export default Equipment;
